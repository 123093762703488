import { LinkType } from 'pages/sharedModelTypes';

export interface SiblingsList {
	heading: string;
	items: LinkType[];
}

export const ListingPageURLs = [
	'/data-och-statistik/',
	'/en/data-and-statistics/',
	'/vagledning-och-stod/',
	'/en/guidance/',
	'/publikationer/',
	'/en/publications/',
	'/lagar-och-regler/foreskrifter-och-allmanna-rad/',
];
