/**
 * FilterDropdown
 */

import React, { useEffect, useState, useRef } from 'react';
import {
	FilterButtonContainer,
	FilterOptionsContainer,
	FilterButton,
	FilterBlock,
	CheckboxWrapper,
	TimeIntervalDropdown,
	CheckboxesInline,
	LabelButtonWrapper,
	FilterRegPageButton,
} from './FilterDropdown.styles';
import Icon from 'components/Boilerplate/Icon';
import {
	FacetModel,
	FacetOption,
	FacetTypeOf,
	SortModel,
} from 'components/SearchByListngPage/SearchByListngPage.model';
import MultiSelectCheckbox from './MultiSelectCheckbox';
import { ChildrenWrapper } from './MultiSelectCheckbox.styles';
import SingleSelectCheckbox from './SingleSelectCheckbox';
import { DateSelectDropdown } from './DateSelectDropdown';
import Checkbox from 'components/Checkbox';
import { LabelContainer, LabelWrapper } from 'components/Panels/Panels.styles';
import { FieldsetInline } from 'components/SearchByListngPage/SearchByListngPage.styles';
import { RadioButtonContainer } from 'pages/NVsePublicationsListingPage/NVsePublicationsListingPage.styles';
import Radio from 'components/Radio';

interface Props {
	/** Facets & sort by  */
	facetModels?: FacetModel[];
	onChange?: (filter: any, type: FacetTypeOf) => void;
	onDateSelectChanged?: (
		range: { from: number; to: number },
		filterTypeOf: string
	) => void;
	onCheckboxChanged?: (checked: boolean, type: string) => void;
	sortModel?: SortModel;
	onSortChanged?: (sort: any) => void;
	isRegulationPage?: boolean;
}

/** Main description for this component. */
const FilterDropdown: React.FC<Props> = ({
	facetModels,
	onChange,
	onDateSelectChanged,
	onCheckboxChanged,
	sortModel,
	onSortChanged,
	isRegulationPage,
}) => {
	interface FacetObj {
		[key: string]: boolean;
	}

	const initFiltersByKey = () => {
		const obj: FacetObj = {};
		// added facets
		facetModels?.forEach((i) => {
			obj[i.key] = false;
		});
		return obj;
	};

	const [fKey, setFKey] = useState(initFiltersByKey());
	const [sSF, setSSF] = useState<string | null>();

	const wrapperRef = useRef<HTMLFieldSetElement | null>(null);
	const radioBtnRef = useRef<HTMLFieldSetElement | null>(null);

	/* useEffect to handle events on filters */
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			const isOutsideWrapper =
				wrapperRef?.current &&
				'contains' in wrapperRef.current &&
				!wrapperRef.current.contains(event.target as Node) &&
				(event.target as HTMLElement).tagName !== 'BUTTON';

			/* const isOutsideRadio =
				radioBtnRef?.current &&
				'contains' in radioBtnRef.current &&
				!radioBtnRef.current.contains(event.target as Node) &&
				(event.target as HTMLElement).tagName !== 'BUTTON'; */

			if (isOutsideWrapper) {
				/* close all filters */
				setFKey((prevK) => {
					const updFKey = { ...prevK };
					for (const filterKey in updFKey) {
						updFKey[filterKey] = false;
					}
					return updFKey;
				});
			}
		};

		const handleKeyDown = (event: { key: string }) => {
			if (event.key === 'Escape') {
				/* close all filters */
				setFKey((prevK) => {
					const updFKey = { ...prevK };
					for (const filterKey in updFKey) {
						updFKey[filterKey] = false;
					}
					return updFKey;
				});
			}
		};

		// Bind the event listeners
		document.addEventListener('mousedown', handleClickOutside);
		document.addEventListener('keydown', handleKeyDown);

		return () => {
			// Unbind the event listeners on clean up
			document.removeEventListener('mousedown', handleClickOutside);
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [wrapperRef, radioBtnRef]);

	const toggleFOpts = (modelKey: string) => {
		setFKey((prevK) => {
			const updFKey = { ...prevK };
			updFKey[modelKey] = !prevK[modelKey];

			for (const filterKey in updFKey) {
				if (filterKey !== modelKey) {
					updFKey[filterKey] = false;
				}
			}
			return updFKey;
		});
	};

	/* toggle select single filter */
	const toggleSSF = (
		event: any,
		type: FacetTypeOf,
		facetKey: string,
		selected: boolean
	) => {
		const key = event.target.value;
		setSSF(key);
		if (onChange) onChange(event, type);
		setFKey((prevK) => {
			const updFKey = { ...prevK };
			if (key === sSF && selected) {
				updFKey[facetKey] = true;
			} else {
				updFKey[facetKey] = false;
			}
			return updFKey;
		});
	};

	const handleDateSelectChanged = (range: any, filterTypeOf: string) => {
		// ask here if you want to close the dropdown
		// ask if there is other filter selected (help from backend)
		if (onDateSelectChanged) {
			onDateSelectChanged(range, filterTypeOf);
		}
		/* close all filters */
		setFKey((prevK) => {
			const updFKey = { ...prevK };
			for (const filterKey in updFKey) {
				updFKey[filterKey] = false;
			}
			return updFKey;
		});
	};

	return (
		<FilterButtonContainer isRegulationPage={isRegulationPage}>
			{facetModels &&
				facetModels.map((facet, i: number) => {
					const selectedName =
						facet.facetOptions.find((option) => option.selected)?.name || '';
					return (
						<React.Fragment key={facet.key + 'filterDropdown'}>
							{facet.key && (
								<FilterBlock>
									{facet.facetType !== FacetTypeOf.bool && (
										<LabelButtonWrapper isRegulationPage={isRegulationPage}>
											{isRegulationPage ? (
												<>
													<LabelWrapper>
														<legend aria-label={facet.key}>{facet.name}</legend>
													</LabelWrapper>
													<FilterRegPageButton
														filterKey={facet.key}
														data-facet-key={facet.key}
														aria-haspopup="true"
														onClick={() => toggleFOpts(facet.key)}
														aria-expanded={fKey[facet.key]}
														isOpen={fKey[facet.key]}
														key={
															Math.random().toString() + facet.key + facet.name
														}
													>
														<span className="unselectable">{selectedName}</span>
														<Icon size={1} icon="chevron" />
													</FilterRegPageButton>
												</>
											) : (
												<FilterButton
													data-facet-key={facet.key}
													aria-haspopup="true"
													onClick={() => toggleFOpts(facet.key)}
													aria-expanded={fKey[facet.key]}
													isOpen={fKey[facet.key]}
													key={
														Math.random().toString() + facet.key + facet.name
													}
												>
													<span className="unselectable">{facet.name}</span>
													<Icon size={1} icon="chevron" />
												</FilterButton>
											)}
										</LabelButtonWrapper>
									)}

									{fKey[facet.key] &&
										facet.facetType === FacetTypeOf.multiSelect && (
											<FilterOptionsContainer id={facet.key} ref={wrapperRef}>
												<legend>{facet.name}</legend>
												{facet.facetOptions &&
													facet.facetOptions.map(
														(option: FacetOption, index: number) => {
															const isFirst = index === 0;
															const isLast =
																index === facet.facetOptions.length - 1;
															return (
																<MultiSelectCheckbox
																	key={option.key + index.toString() + 'msf'}
																	checked={option.selected}
																	onCheckedChanged={(event) =>
																		onChange &&
																		onChange(event, FacetTypeOf.multiSelect)
																	}
																	id={facet.key}
																	value={option.key}
																	name={option.name}
																	hits={option.hits}
																	focusStyle={
																		isFirst ? 'first' : isLast ? 'last' : ''
																	}
																>
																	<ChildrenWrapper>
																		<div className="txt_wrapper">
																			<span className="name">
																				{option.name}
																			</span>
																			{option.helpText && (
																				<span className="help">
																					{option.helpText}
																				</span>
																			)}
																		</div>
																		<span className="hits">
																			({option.hits})
																		</span>
																	</ChildrenWrapper>
																</MultiSelectCheckbox>
															);
														}
													)}
											</FilterOptionsContainer>
										)}

									{fKey[facet.key] &&
										facet.facetType === FacetTypeOf.singleSelect && (
											<FilterOptionsContainer id={facet.key} ref={wrapperRef}>
												<legend>{facet.name}</legend>
												{facet.facetOptions &&
													facet.facetOptions.map(
														(option: FacetOption, index: number) => {
															const isFirst = index === 0;
															const isLast =
																index === facet.facetOptions.length - 1;
															return (
																<SingleSelectCheckbox
																	key={option.key + index.toString() + 'ssf'}
																	checked={option.selected}
																	onCheckedChanged={(event) =>
																		toggleSSF(
																			event,
																			FacetTypeOf.singleSelect,
																			facet.key,
																			option.selected
																		)
																	}
																	id={facet.key}
																	value={option.key}
																	name={option.name}
																	focusStyle={
																		isFirst ? 'first' : isLast ? 'last' : ''
																	}
																>
																	<span className="name">{option.name}</span>
																</SingleSelectCheckbox>
															);
														}
													)}
											</FilterOptionsContainer>
										)}

									{fKey[facet.key] &&
										facet.facetType === FacetTypeOf.dateSelect && (
											<FilterOptionsContainer id={facet.key} ref={wrapperRef}>
												<legend>{facet.name}</legend>
												{facet.facetOptions &&
													facet.facetOptions.map(
														(option: FacetOption, index: number) => {
															const isFirst = index === 0;
															const isLast =
																index === facet.facetOptions.length - 1;

															return (
																<>
																	{!option.timeInterval && (
																		<SingleSelectCheckbox
																			key={
																				option.name + index.toString() + 'dsf'
																			}
																			checked={option.selected}
																			onCheckedChanged={(event) =>
																				toggleSSF(
																					event,
																					FacetTypeOf.singleSelect,
																					facet.key,
																					option.selected
																				)
																			}
																			id={facet.key}
																			value={option.key}
																			name={option.name}
																			focusStyle={
																				isFirst ? 'first' : isLast ? 'last' : ''
																			}
																		>
																			<span className="name">
																				{option.name}
																			</span>
																		</SingleSelectCheckbox>
																	)}

																	{option.timeInterval && (
																		<TimeIntervalDropdown>
																			<div className="separator" />
																			<span>{option.name}</span>

																			<DateSelectDropdown
																				onSubmit={(range) =>
																					handleDateSelectChanged &&
																					handleDateSelectChanged(
																						range,
																						facet.key
																					)
																				}
																				timeInterval={option.timeInterval}
																			/>
																		</TimeIntervalDropdown>
																	)}
																</>
															);
														}
													)}
											</FilterOptionsContainer>
										)}

									{facet.facetType === FacetTypeOf.bool && (
										<CheckboxWrapper isRegulationPage={isRegulationPage}>
											<LabelWrapper>
												<legend>{facet.name}</legend>
											</LabelWrapper>
											<CheckboxesInline>
												<Checkbox
													checked={facet.facetOptions[0].selected}
													onCheckedChanged={(value: boolean) => {
														onCheckboxChanged &&
															onCheckboxChanged(value, facet.key);
													}}
													isRegulationPage={isRegulationPage}
												>
													{facet.facetOptions[0].name}
												</Checkbox>
											</CheckboxesInline>
										</CheckboxWrapper>
									)}
								</FilterBlock>
							)}
						</React.Fragment>
					);
				})}

			{isRegulationPage && sortModel && (
				<FieldsetInline>
					<LabelContainer>
						<LabelWrapper>
							<legend aria-label={sortModel.key}>{sortModel.name}</legend>
						</LabelWrapper>
						<RadioButtonContainer>
							{sortModel.sortOptionModels.map((option, index) => {
								const selectedOpt = option.selected ? option.key : '';
								return (
									<Radio
										key={option.key}
										id={option.key}
										label={option.name}
										options={[{ id: option.key, value: option.name }]}
										selectedId={selectedOpt}
										onSelectedChanged={(option) =>
											onSortChanged && onSortChanged(option)
										}
									/>
								);
							})}
						</RadioButtonContainer>
					</LabelContainer>
				</FieldsetInline>
			)}
		</FilterButtonContainer>
	);
};

export default FilterDropdown;
