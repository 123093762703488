import EpiFragments from 'components/Boilerplate/EpiFragments';
import {
	BlockType,
	getLastItemBlockType,
	getSpaceToAddBefore,
} from 'components/Boilerplate/EpiFragments/EpiFragments';
import { Grid } from 'components/Boilerplate/Grid';
import { ListingPageIndexPanel } from 'components/Panels/ListingPageIndexPanel';
import React, { useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { ViewType } from 'types/enums';
import { NVseSubjectAreaListingPageModel } from './NVseSubjectAreaListingPage.model';
import ListingPageGrantsHeader from 'components/ListingPageGrantsHeader';
import { ListingPageIndexPanelWrapper } from './NVseSubjectAreaListingPage.styles';

/**
 * # Listningssida för ämnen
 * Modeltype:<code>NVseSubjectAreaListingPage</code>
 *
 * [API contract](https://consid.atlassian.net/wiki/spaces/NNN/pages/2233892865/NVseSubjectAreaListingPage)
 *
 * Landningssida för webbplatsens ämnesområden
 */
const NVseSubjectAreaListingPage: React.FC<NVseSubjectAreaListingPageModel> = ({
	heading,
	preamble,
	bottomItems,
	epi,
	subjectAreaListingPageItems,
	topItems,
	_properties = {},
	disableCustomHeadingLogic,
	themeTop,
}) => {
	const themeContext = useContext(ThemeContext);

	// TODO: hardcoded text
	const showMoreLabel = 'Visa fler';

	let lastBlockType = BlockType.Element;
	let blockTypeBefore_bottomItems: BlockType = lastBlockType;

	if (topItems && topItems.length > 0) {
		lastBlockType = getLastItemBlockType(
			topItems,
			themeContext,
			disableCustomHeadingLogic
		);
		blockTypeBefore_bottomItems = lastBlockType;
	}

	if (
		subjectAreaListingPageItems &&
		Object.keys(subjectAreaListingPageItems).length > 0
	) {
		lastBlockType = BlockType.Element;
		blockTypeBefore_bottomItems = lastBlockType;
	}

	if (bottomItems && bottomItems.length > 0) {
		lastBlockType = getLastItemBlockType(
			bottomItems,
			themeContext,
			disableCustomHeadingLogic
		);
	}

	const spaceAfterLast = getSpaceToAddBefore(
		lastBlockType,
		BlockType.SectionBanner,
		themeContext
	);

	return (
		<>
			<ListingPageGrantsHeader
				heading={heading}
				headingLevel={1}
				preamble={preamble}
				heading_htmlAttributes={_properties?.heading}
				preamble_htmlAttributes={_properties?.preamble}
				themeTop={themeTop}
				themeContext={themeContext}
				isSubjectAreaPage={true}
			></ListingPageGrantsHeader>
			<ListingPageIndexPanelWrapper>
				{subjectAreaListingPageItems &&
					Object.keys(subjectAreaListingPageItems).length > 0 && (
						<Grid paddingTop={false} paddingBottom={false}>
							<ListingPageIndexPanel
								items={subjectAreaListingPageItems}
							></ListingPageIndexPanel>
						</Grid>
					)}
			</ListingPageIndexPanelWrapper>
		</>
	);
};

export default NVseSubjectAreaListingPage;
