/**
 * ListingPageGrantsHeader
 */

import React from 'react';
import {
	Div,
	Preamble,
	PublicationLink,
} from './ListingPageGrantsHeader.styles';
import { Heading } from 'components/Typography/Typography';
import Space from 'components/Boilerplate/Space';
import EditableField from 'components/EditableField/EditableField';
import {
	LinkExtendedType,
	PuffTheme,
	getThemeHeadingColor,
	getThemeTextColor,
} from 'pages/sharedModelTypes';
import { Grid } from 'components/Boilerplate/Grid';
import Link from 'components/Boilerplate/Link';

export interface ListingPageGrantsHeaderProps {
	/** Description of this property */
	heading?: string | null;
	headingLevel?: number;
	preamble?: string | null;
	themeContext?: any;
	heading_htmlAttributes?: any;
	preamble_htmlAttributes?: any;
	themeTop?: PuffTheme | null;
	publicationLink?: LinkExtendedType | null;
	isSubjectAreaPage?: boolean;
}

/** Main description for this component. */
const ListingPageGrantsHeader: React.FC<ListingPageGrantsHeaderProps> = ({
	themeTop,
	heading,
	headingLevel,
	preamble,
	heading_htmlAttributes = {},
	preamble_htmlAttributes = {},
	themeContext,
	publicationLink,
	isSubjectAreaPage = false,
}) => {
	return (
		<Div themeTop={themeTop} isSubjectAreaPage={isSubjectAreaPage}>
			<Grid
				paddingTop={false}
				paddingBottom={false}
				padding={false}
				className="w-list-page-grants-header"
			>
				<EditableField opeProperty={heading_htmlAttributes}>
					{heading && (
						<Heading
							level={headingLevel}
							color={getThemeHeadingColor(themeContext, themeTop)}
						>
							{heading}
						</Heading>
					)}
				</EditableField>
				<Space bottom={0} className="preamble-w-mb">
					<EditableField opeProperty={preamble_htmlAttributes}>
						{preamble && (
							<Space top={0} bottom={0}>
								<Preamble themeTop={themeTop} {...preamble_htmlAttributes}>
									{preamble}
								</Preamble>
							</Space>
						)}
					</EditableField>
					{publicationLink && (
						<Space top={1} bottom={1}>
							<PublicationLink themeTop={themeTop}>
								{publicationLink.title}

								<Link
									to={publicationLink.url}
									target={publicationLink.target}
									external={publicationLink.isFile}
								>
									{publicationLink.text}
								</Link>
							</PublicationLink>
						</Space>
					)}
				</Space>
			</Grid>
		</Div>
	);
};

export default ListingPageGrantsHeader;
