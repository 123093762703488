import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	Model,
	NavigationItem,
	SearchListingResultModel,
	SearchResultModel,
	SessionStorageModel,
} from 'types/epi';
import { ContentTypes } from 'types/pages';
import { RootState, AppThunk } from 'store';
import {
	getEpiPage,
	getEpiSearchResult,
	getPagegontentWithContentLink,
} from 'api/epiApi';
import { AxiosError } from 'axios';
import {
	createPageSessionStorage,
	getSessionStorage,
} from 'hooks/useSessionStorage';

interface ModelState {
	model: Model;
	error: string | null;
	loading: boolean;
	sessionStorage: SessionStorageModel[];
}

export const ListingModelsType = [
	'NVseGuidanceListingPage',
	'NVseStatisticsListingPage',
	'NVseStandardListingPage',
	'NVsePublicationsListingPage',
	'NVseRegulationsListingPage',
];

const initialState: ModelState = {
	error: null,
	loading: false,
	sessionStorage: [],
	model: {
		content: undefined,
		react: undefined,
	},
};

export const unsetNavigationLinksIsActive = (items: NavigationItem[]) => {
	items.forEach((item) => {
		item.isActive = false;
		if (item.children && item.children.length > 0) {
			unsetNavigationLinksIsActive(item.children);
		}
	});
};

const slice = createSlice({
	name: 'model',
	initialState,
	reducers: {
		getModelStart: (state) => {
			state.loading = true;
		},
		getModelSuccess: (state, action: PayloadAction<Model>) => {
			// TODO: Strange place for this check?.. rethink
			// do the same with MicrositeStartPage
			if (action.payload.content?.modelType === 'NVseStartPage') {
				action.payload.breadcrumbs = { breadcrumbs: [] };
			}

			if (action.payload.navigation && action.payload.navigation.items) {
				unsetNavigationLinksIsActive(action.payload.navigation.items);
			}
			state.model = action.payload;

			state.error = null;
			state.loading = false;
		},
		getModelFailed: (state, action: PayloadAction<any>) => {
			if (action.payload.error) {
				state.error = action.payload.error;
			}

			if (action.payload.data) {
				state.model = action.payload.data;
			}

			state.loading = false;
		},
		updateContent: (state, action: PayloadAction<ContentTypes>) => {
			state.model.content = action.payload;
		},
		setSessionStorage: (state, action: PayloadAction<SessionStorageModel>) => {
			if (!state.sessionStorage) {
				state.sessionStorage = [];
			}

			const index = state.sessionStorage.findIndex(
				(item) =>
					item.modelType === action.payload.modelType &&
					item.id === action.payload.id
			);

			if (index === -1) {
				state.sessionStorage.push(action.payload);
			} else {
				state.sessionStorage[index] = action.payload;
			}
		},
		toggleMenuItem: (state, action: PayloadAction<number[]>) => {
			if (state.model.navigation) {
				let item = state.model.navigation.items[action.payload[0]];
				for (let i = 1; i < action.payload.length; i++) {
					item = item.children[action.payload[i]];
				}
				item.isActive = !item.isActive;
			}
		},
		getSearchStart: (state, action: PayloadAction<string>) => {
			ListingModelsType.includes(action.payload)
				? (state.loading = false)
				: (state.loading = true);
		},
		getSearchSuccess: (state, action: PayloadAction<SearchResponse>) => {
			// Response from the api.
			let searchListingResultModel: SearchListingResultModel | null = null;
			const respons = action.payload.data as SearchListingResultModel;
			searchListingResultModel = {
				searchModel: respons.searchModel,
				numberOfSearchResults: respons.numberOfSearchResults,
			} as SearchListingResultModel;

			const resultModel = searchListingResultModel;
			updateStateSessonStorage(
				state,
				action.payload.modelType,
				action.payload.id,
				action.payload.sessionStoragePropName,
				resultModel
			);
			state.loading = false;
		},
		getSearchFailed: (state, action: PayloadAction<SearchResponse>) => {
			const resultModel = {
				searchModel: {},
				numberOfSearchResults: '',
			} as SearchListingResultModel;
			updateStateSessonStorage(
				state,
				action.payload.modelType,
				action.payload.id,
				action.payload.sessionStoragePropName,
				resultModel
			);

			// state.loading = false;
		},
	},
});

const updateStateSessonStorage = (
	state: ModelState,
	modelType: string,
	id: string,
	sessionStoragePropName: string,
	resultModel: SearchResultModel | SearchListingResultModel | null
) => {
	// Find page session data

	const pageSessionStorage = getSessionStorage(
		state.sessionStorage,
		modelType,
		id
	);
	if (pageSessionStorage) {
		// Initilize data
		if (!pageSessionStorage.data) {
			pageSessionStorage.data = {};
		}
		// Update result
		pageSessionStorage.data[sessionStoragePropName] = resultModel;
	} else {
		// No page session found, create one and add it to sessionStorage list.
		const data = {
			[sessionStoragePropName]: resultModel,
		};

		const newPageSessionStorage = createPageSessionStorage(modelType, id, data);
		// Initilize sessionStorage
		if (!state.sessionStorage) {
			state.sessionStorage = [];
		}
		state.sessionStorage.push(newPageSessionStorage);
	}
};

export const fetchPage = (
	routeApiUrl: string,
	route: string
): AppThunk => async (dispatch) => {
	let data;

	dispatch(getModelStart());

	try {
		data = await getEpiPage(routeApiUrl, route);
	} catch (err: any) {
		const contentType = err.response.headers['content-type'];

		if (contentType && contentType.indexOf('application/json') !== -1) {
			// process your JSON data further
			dispatch(
				getModelFailed({ data: err.response.data, error: err.toString() })
			);
		} else {
			// this is text, do something with it
			dispatch(getModelFailed({ data: undefined, error: err.toString() }));
		}

		//		dispatch(getModelFailed(err));
		return;
	}

	dispatch(getModelSuccess(data));
};

export const fetchPageWithContentLink = (
	contentLink: string
): AppThunk => async (dispatch) => {
	let data;
	try {
		data = await getPagegontentWithContentLink(contentLink);
	} catch (error) {
		console.log(error);
		return;
	}
	if (data.status === 200) {
		dispatch(updateContent(data.result));
	}
};

export const fetchSearchResult = (
	modelType: string,
	id: string,
	sessionStoragePropName: string,
	routeApiUrl: string,
	route: string,
	query: string
): AppThunk => async (dispatch) => {
	let data;

	dispatch(getSearchStart(modelType));

	try {
		// TODO: Type data <T>
		data = await getEpiSearchResult(routeApiUrl, route, query);
	} catch (err: any) {
		const reason = err as AxiosError;
		const statusCode: number | undefined = reason.response?.status;
		switch (statusCode) {
			case 400: // 400 Bad request
				console.log(
					'TODO: Handle 400, StatusText:' + reason.response!.statusText
				);
				break;
			case 500: // 500 Internal server error
				console.log(
					'TODO: Handle 500, StatusText:' + reason.response!.statusText
				);
				break;
			default:
				console.log('TODO: Handle unsupported status code ' + statusCode);
		}

		let result = {
			modelType,
			id,
			sessionStoragePropName,
			data: null,
			error: err.toString(),
		} as SearchResponse;

		dispatch(getSearchFailed(result));
		return;
	}
	let result = {
		modelType,
		id,
		sessionStoragePropName,
		data,
	} as SearchResponse;

	dispatch(getSearchSuccess(result));
};

export type SearchResponse = {
	modelType: string;
	id: string;
	sessionStoragePropName: string;
	data: any;
	error: string;
};

export const selectModel = (state: RootState) => state.model;
export const selectContent = (state: RootState) => state.model.model.content;
export const selectSearch = (state: RootState) => state.model.model.search;
export const selectMetaData = (state: RootState) => state.model.model.metadata;
export const selectLocalization = (state: RootState) =>
	state.model.model.localization;
export const selectNavigation = (state: RootState) =>
	state.model.model.navigation;
export const selectLang = (state: RootState) => state.model.model.lang;
export const selectQuickLinks = (state: RootState) =>
	state.model.model.quickLinks?.quickLinks;
export const selectBreadcrumbs = (state: RootState) =>
	state.model.model.breadcrumbs?.breadcrumbs;
export const selectCookieBar = (state: RootState) =>
	state.model.model.cookieBar;
export const selectFooter = (state: RootState) => state.model.model.footer;
export const selectTracer = (state: RootState) => state.model.model.tracer;
export const selectSessionStorage = (state: RootState) =>
	state.model.sessionStorage;
export const selectGlobalMessages = (state: RootState) =>
	state.model.model.globalMessages;

export const selectMicrosite = (state: RootState) =>
	state.model.model.microsite;

export const selectLogo = (state: RootState) => state.model.model.logo;
export const getSkipToContent = (state: RootState) =>
	state.model.model.quickLinks?.skipToContent;
export const selectAppLayout = (state: RootState) =>
	state.model.model.appTypeLayout;

export const {
	getModelStart,
	getModelSuccess,
	getModelFailed,
	getSearchStart,
	getSearchSuccess,
	setSessionStorage,
	getSearchFailed,
	updateContent,
	toggleMenuItem,
} = slice.actions;

export default slice.reducer;
