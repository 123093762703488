/**
 * PuffListItem
 */
import React, { useContext } from 'react';
import {
	ComponentWithBorderLeft,
	PuffListItemImageStyle,
	PuffListItemLink,
	PuffListItemLinkStyle,
	PuffListItemMetadata,
	PuffListItemMetadataCompact,
	PuffListItemPreamble,
	PuffListItemTextStyle,
	PuffTimeStyle,
} from './PuffListItem.styles';
import { LinkType } from 'pages/sharedModelTypes';
import { ImageFragmentModel } from 'types/fragments';
import Image from 'components/Boilerplate/Image';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import {
	CommonDecalStyle,
	ListingPageDecalStyle,
	NFSText,
} from 'components/Panels/Panels.styles';
import { ThemeContext } from 'styled-components';
import { useImageTitle } from 'hooks/useImageTitle';

export interface PuffListItemProps {
	/**
	 * Preamble text
	 */
	decal?: string | null;
	/**
	 * Heading level
	 */
	headingLevel?: number | undefined;
	/**
	 * Preamble text
	 */
	preamble?: string | null;
	/**
	 * Link
	 */
	link?: LinkType | null;

	/**
	 * Image
	 */
	image?: ImageFragmentModel | null;

	/**
	 * Compact view
	 * (no image)
	 */
	compact?: boolean;

	/**
	 * optional for microsite
	 */
	publicationDate?: string;
	nfsText?: string | null;
}

/**
 * # PuffListItem
 *
 * Puff List item
 */
const PuffListItem: React.FC<PuffListItemProps> = ({
	decal,
	headingLevel,
	link,
	preamble,
	image,
	compact,
	children,
	publicationDate,
	nfsText,
}) => {
	const themeContext = useContext(ThemeContext);

	const isPhone = useMediaQuery({
		minWidth: breakpointsNumber.phone,
		maxWidth: breakpointsNumber.tablet - 1,
	});

	const title = useImageTitle(
		image?.photographer,
		image?.agency,
		image?.illustrator
	);

	const showImage = image && !compact && !isPhone;

	if (!link) return null;

	return (
		<PuffListItemLinkStyle
			to={link.url}
			aria-label={link?.heading}
			external={link.isFile}
		>
			{themeContext.isMicrositeActive && <ComponentWithBorderLeft />}

			<PuffListItemTextStyle>
				{decal && <CommonDecalStyle>{decal}</CommonDecalStyle>}
				{publicationDate && (
					<PuffTimeStyle>
						<time dateTime={publicationDate}>{publicationDate}</time>
					</PuffTimeStyle>
				)}
				{link && (
					<PuffListItemLink
						zeroBottom={true}
						color={
							themeContext.isMicrositeActive
								? themeContext.colors.primary
								: themeContext.colors.secondaryBlue
						}
						styleLevel={4}
						level={headingLevel}
					>
						{link.heading}
					</PuffListItemLink>
				)}
				{preamble && <PuffListItemPreamble>{preamble}</PuffListItemPreamble>}
				{children && !compact && (
					<PuffListItemMetadata>{children}</PuffListItemMetadata>
				)}
				{nfsText && !isPhone && <NFSText>{nfsText}</NFSText>}
			</PuffListItemTextStyle>

			{showImage && (
				<PuffListItemImageStyle>
					<Image
						alt={image?.alt}
						src={image?.src}
						srcSet={image?.srcSet}
						title={title}
					></Image>
				</PuffListItemImageStyle>
			)}

			{children && compact && (
				<PuffListItemMetadataCompact>{children}</PuffListItemMetadataCompact>
			)}
		</PuffListItemLinkStyle>
	);
};

export default PuffListItem;
