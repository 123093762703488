/**
 * ListingPageGrantsHeader - styles
 */

import { getThemeBackground, getThemeTextColor } from 'pages/sharedModelTypes';
import styled from 'styled-components';
import { mediaQueries } from 'theme';
import { ListingPageGrantsHeaderProps } from './ListingPageGrantsHeader';
import { zeroMarginPadding } from 'components/Typography';

export const Div = styled.div<ListingPageGrantsHeaderProps>`
	background-color: ${(p) => getThemeBackground(p.theme, p.themeTop)};
	display: flex;
	height: auto;
	min-height: auto;
	padding: ${(p) => (p.isSubjectAreaPage ? '64px 0 80px 0' : '64px 0 168px 0')};

	.preamble-w-mb {
		max-width: 700px;
	}

	.w-list-page-grants-header {
		width: 1100px;
	}

	${mediaQueries.phoneOrTablet} {
		padding: 64px 0 148px 16px;
		padding: ${(p) =>
			p.isSubjectAreaPage ? '64px 0 40px 16px' : '64px 0 148px 16px'};
		.w-list-page-grants-header {
			width: 1100px;
		}
	}

	${mediaQueries.phone} {
		${(props) => props.theme.spacing.space2}rem;
	}
`;

export const Preamble = styled.p<ListingPageGrantsHeaderProps>`
	${zeroMarginPadding};
	${(props) => props.theme.typography.style7.getStyle()};
	color: ${(p) => getThemeTextColor(p.theme, p.themeTop)};
`;

export const PublicationLink = styled.p<ListingPageGrantsHeaderProps>`
	display: flex;
	flex-direction: column;
	${(props) => props.theme.typography.style8.getStyle()};
	color: ${(p) => getThemeTextColor(p.theme, p.themeTop)};

	a {
		display: inline-block;
		color: ${(p) => getThemeTextColor(p.theme, p.themeTop)} !important;
		${(props) => props.theme.typography.style8.getStyle()};
		margin-top: ${(p) => p.theme.spacing.space2}rem;
	}
`;
